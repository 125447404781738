import React from 'react';
import './Pardon.css';
import login from './img/pardon/login.png';
import register from './img/pardon/register.png';
import plates from './img/pardon/plates.png';
import plates2 from './img/pardon/plates2.png';
import profile from './img/pardon/profile.png';

function Pardon() {
    return (
        <div className="Pardon">
            <header className="Pardon-header">
                <p className='Pardon-header-text'>Pardon! Request The Vehicle To Be Moved - Mobile App</p> 
                <p className='Pardon-header-p'>Pardon! is an application that drivers can use to notify the owners of improperly parked vehicles. When the license plate of a wrongly parked vehicle is entered, the application sends a notification to the owner, requesting them to move their vehicle. The application has been developed using .NET, EntityFramework, and React Native.</p>

                <p className='Pardon-header-text'>Login and Register</p>
                <div className="image-container">
                    <img src={login} alt="Login Screen"/>
                    <img src={register} alt="Register Screen"/>
                </div>
                <p className='Pardon-header-text'>Register Plate</p>
                <div className='image-container'>
                <img src={plates} alt="Plates Screen"/>
                <img src={plates2} alt="Plates2 Screen"/>
                </div>
                <p className='Pardon-header-p-center'>In this screen you can register your plate and manage registered ones.</p>
                
                <p className='Pardon-header-text'>Profile</p>
                <img src={profile} alt="Profile Screen"/>
                <p className='App-header-p-center'>Here, you can go to your plates, change the theme and reset your password. Also you can permanently delete your account here.</p>
            </header>
        </div>
    );
}

export default Pardon;
