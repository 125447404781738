import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import Sms from './Sms';
import Pardon from './Pardon';
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <Router>
      <Analytics/>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/sms" element={<Sms />} />
          <Route path="/pardon" element={<Pardon />} />
        </Routes>
    </Router>
  );
}

export default App;
