import React from 'react';
import './App.css';
import mainmenu from './img/mainmenu.png';
import classes from './img/classes.png';
import homeworks from './img/homeworks.png';
import students from './img/students.png';
import attendence from './img/attendence.png';
import assignaclass from './img/assignaclass.png';
function Sms() {
    return (
        <div className="App">
          <header className="App-header">
           <p className='App-header-text'>Student Management System </p> 
           <p className='App-header-p'><p className='App-header-p'>Originally named "ÖğrenciBis," which translates to "Student Management System" from Turkish, this project allows you to manage almost every aspect of your school or private course. In the Admin section, you can oversee all classes, students, teachers, homework assignments, exams, and more. There is also a mobile app version for teachers, enabling them to assign and check homework as well as take student attendance. For the backend, .NET Core, EntityFramework, and PostgreSQL were used. The frontend was developed with Blazor and MudBlazor.</p>
</p>

           <p className='App-header-text'>Admin Panel</p>
           <img src={mainmenu} alt="Main Menu Screen"/>
           <p className='App-header-p-center'>The basic home screen. By default, there are three cards for "Students," "Teachers," and "Classes." Additional cards can be added as widgets as needed.</p>
           <img src={classes} alt="Classes Screen"/>
           <p className='App-header-p-center'>Classes screen. You can view all classes in the system, add, modify, or delete them, and see the students and their assigned homework for each class.</p>
           <img src={homeworks} alt="Homeworks Screen"/>
           <p className='App-header-p-center'>Homework screen. You can view all homework assignments given by teachers and check if students have completed their assignments.</p>
           <img src={students} alt="Students Screen"/>
           <p className='App-header-p-center'>Students screen. You can view all students and their registered parents, and you can add, modify, or delete student records.</p>
           <img src={attendence} alt="Attendence screen"/>
           <p className='App-header-p-center'>Attendance screen. As an admin, you can take attendance and generate attendance reports for any student or class.</p>
           <img src={assignaclass} alt="Assign A Class Screen"/>
           <p className='App-header-p-center'>Assign a Class to a Teacher screen. Here, you can assign teachers to classes. This schedule will appear on the teacher's mobile app, allowing them to track their classes.</p>
          </header>
        </div>
      );

}

export default Sms;
