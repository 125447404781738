import { Link } from 'react-router-dom';
import './App.css';
import Sms from './Sms';
function Home() {
  return (
    <div className="App">
      <header className="App-header">
        
        <p className='App-header-text'>
         Welcome
        </p>
        <p className='App-header-p'> My name is Behlulcan and you are now in my website.</p>

        <p className='App-header-text'>Online Projects</p>

        <a href="https://see-your-sky.vercel.app" className='App-link'>SeeYourSky</a>
        <a href="https://lookbetween.vercel.app" className='App-link'>Meme Text Converter</a>
        <a href="https://burcu-two.vercel.app/" className='App-link'>Professional Artist Website</a>

        <p className='App-header-text'>
          Offline Projects
        </p>
       <Link to="/sms" className='App-link'>Student Management System</Link>
       <Link to="/pardon" className='App-link'>Vehicle Parking Solutions App</Link>
       <p className='App-header-text'>
          Contact
        </p>
        <a href="https://www.linkedin.com/in/behlulcan/" className='App-link'>LinkedIn</a>
        <a href="https://twitter.com/behlulcansahin" className='App-link'>Twitter</a>
        <a href="https://github.com/voiicez" className='App-link'>Github</a>
        <a href="mailto:behlulcansahin@gmail.com" className='App-link'>Email</a>
        <p className='App-header-text'>
          More
        </p>
        <Link to="/blog" className='App-link'>Blog Posts</Link>
      </header>
    </div>
  );
}

export default Home;